import React, {Component} from "react";
import {Button, Form, Icon, List} from "semantic-ui-react";

export default class EditField extends Component{
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            attribute: props.attribute,
            prevAttribute: props.prevAttribute,
            editField: !!(props.justEdit),
            input: valueIsString(props.attribute)?props.attribute.readableValue:JSON.stringify(props?.attribute?.readableValue)
        };
        this.submit = this.submit.bind(this);

    }

    initialInputError(){
        if(typeof this.state.attribute.readableValue === 'string' && this.state.attribute.readableValue &&
            this.state.attribute.possibleValues === null && this.state.attribute.validate) {
            if(this.state.attribute.validate(this.state.attribute.readableValue, this.state.attribute.numOfAttributesFromComponent)){
                return true;
            }
        }
        return false;
    }
    render() {
        return (
            <Form.Group id='formGroup'>
                {this.state.editField ? (
                    <div className={'formField'}>
                        {typeof this.state.attribute.readableValue === 'string' && this.state.attribute.possibleValues === null ? (
                           <Form.Input id='configInput' value={this.state.input} data-attribute={JSON.stringify(this.props.attribute)}
                                        onKeyPress={this.enterPressed}
                                        className={(this.initialInputError()?'error':'')}
                                        onChange={(event) => this.validateInput(event, this.state.attribute)}/>
                        ):(typeof this.state.attribute.readableValue === 'string'? (
                            <> <Form.Dropdown id='configDropdown' selection data-attribute={JSON.stringify(this.props.attribute)}
                                           value={this.state.input}
                                           className={(this.dropdownError(this.state.input, this.state.attribute) ? 'error' : '') + (this.state.attribute.errorMessage ? ' dropdown-error' : '')}
                                           onChange={(event) => this.dropdownChange(event)}
                                           options={this.mapToDropdown(this.state.attribute)}>
                            </Form.Dropdown>

                            {this.state.attribute.errorMessage && (
                                <div className="formField customerror">
                                {this.state.attribute.errorMessage}
                                </div>
                            )} </>
                        ):(
                            <div id='flagGroup'>
                                <Form.Group id='flagFormGroup' data-attribute={JSON.stringify(this.state.attribute)}>
                                    <List>
                                        {this.state.attribute.possibleValues.map((flag, index) => {
                                            return (
                                                <List.Item id='formCheckbox' key={flag.name}>
                                                    {flag.name}
                                                    <br/>
                                                    <Button.Group id='flagButtons' size='mini' flagname={flag.name}>
                                                        <Button type='button' id='flagButton' data-flagname={flag.name} data-flagvalue={flag.trueLabel} data-flagbit={'1'}
                                                                className={`${JSON.parse(this.state.input)[index].value === flag.trueLabel ? "positive" : ""}`}
                                                                onClick={event=> this.trueFalseButtonClick(event,'0')}>{flag.trueLabel}</Button>
                                                        <Button.Or/>
                                                        <Button type='button' id='flagButton' data-flagname={flag.name} data-flagvalue={flag.falseLabel} data-flagbit={'0'}
                                                                className={`${JSON.parse(this.state.input)[index].value === flag.falseLabel ? "positive" : ""}`}
                                                                onClick={event => this.trueFalseButtonClick(event,'1')}>{flag.falseLabel}</Button>
                                                    </Button.Group>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </Form.Group>
                            </div>
                        ))}
                        {!this.props.justEdit ? (
                        <Button.Group>
                            <Form.Button type='button' icon data-attribute={JSON.stringify(this.state.attribute)} data-selectedvalue={this.state.input}>
                                <Icon name='check' id='checkIconButton'  onClick={(event) => this.submit(event)} />
                            </Form.Button>
                            <Form.Button type='button' icon>
                                <Icon name='close' id='closeIconButton' onClick={() => this.close()}/>
                            </Form.Button>
                        </Button.Group>
                        ):<div/>}
                    </div>
                ):(
                    <div style={{display:'flex'}}>
                        {valueIsString(this.state.attribute)? (
                            this.state.input
                        ):(
                            <List>
                                {this.state?.input && JSON.parse(this.state.input).map(flag => {
                                    return (
                                        <List.Item key={flag.name}>
                                            {flag.name} {flag.value}
                                        </List.Item>
                                    )
                                })}
                            </List>
                        )}
                        {this.state?.attribute?.editable?
                            <Icon disabled={(this.state.prevAttribute)?!this.state.prevAttribute.rawValue:false}
                                onClick={() => this.setState({editField: true})} name='pencil' color='yellow'/>:''}
                    </div>
                )}
            </Form.Group>
        )
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps?.attribute?.readableValue !== prevState?.props?.attribute?.readableValue ||
            nextProps.justEdit !== prevState.props.justEdit){
            return { props: nextProps,
                attribute: nextProps.attribute,
                prevAttribute: nextProps.prevAttribute,
                editField: !!(nextProps.justEdit),
                input: valueIsString(nextProps.attribute)?nextProps.attribute.readableValue:JSON.stringify(nextProps.attribute.readableValue)};
        }
        return null;
    }

    trueFalseButtonClick(event, siblingClass) {
        let classList = event.currentTarget.classList;
        let flagName = event.currentTarget.dataset.flagname;
        let flagValue = event.currentTarget.dataset.flagvalue;
        let flagBit = event.currentTarget.dataset.flagbit;
        let sibling = event.currentTarget.parentNode.querySelector('[data-flagbit="'+siblingClass+'"]')

        if(!classList.contains('positive')) {
            sibling.classList.remove('positive');
            classList.add('positive');
        }
        let newFlags = JSON.parse(this.state.input).map(flag => {
            if (flag.name === flagName) {
                flag.value = flagValue;
                flag.bit = flagBit;
            }
            return flag;
        })

        this.setState({input:JSON.stringify(newFlags)})
    }

    dropdownChange(event) {
        this.state.input = event.target.textContent.includes("-") ? event.target.textContent.split("-")[0].trim() : event.target.textContent;
        this.setState({}, () => {
            if (this.props.dropdownChange) this.props.dropdownChange(event);
        })
    }

    mapToDropdown(attributes) {
        let dropdownValues = [];
        let selectedItems = attributes?.selectedvalues;
        if (attributes && attributes?.possibleValues) {
            attributes?.possibleValues.forEach(possibleValue => {
                const additionalClass = selectedItems != null && selectedItems.includes(possibleValue.key) ? 'lightblue' : 'default';
                let dropdownOption = {
                    key: possibleValue.value + '' + attributes.id,
                    text: possibleValue.pin ? possibleValue.key + ' - ' + possibleValue.pin : possibleValue.key,
                    value: possibleValue.key,
                    className: `${additionalClass}`
                }
                if (!possibleValue.hidden) {
                    dropdownValues.push(dropdownOption);
                }
            });
        }
        return dropdownValues;
    }

    enterPressed(event) {
        let code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            event.preventDefault();
            if(!event.target.parentElement.parentElement.parentElement.querySelector('#checkIconButton').classList.contains('disabled')){
                event.target.parentElement.parentElement.parentElement.querySelector('#checkIconButton').click();
            }
        }
    }

    submit(event) {
        if(event.target.parentElement.form){
            event.target.parentElement.form.dispatchEvent(
                new CustomEvent('submit', {cancelable:true, bubbles: true, detail:this.state.input}))
        }
        this.setState({editField: false});
    }

    close() {
        let originalVal = this.state.attribute.readableValue;
        this.setState({editField: false, input: originalVal});
    }

    validateInput(event, attribute) {
        let classList = event.target.classList;
        let checkIcon;
        if(!!(this.props.justEdit)){
            checkIcon = {classList:{add:()=>{}, remove: ()=>{}}};
        } else {
            checkIcon = event.target.parentNode.parentNode.parentNode.querySelector('#checkIconButton');
        }
        if (attribute.validate) {
            const errorMessage = attribute.validate(event.target.value, attribute.numOfAttributesFromComponent);
            if (errorMessage) {
                if (errorMessage == "value should be numerical") {
                    classList.add('error')
                    this.setState({ input: event.target.value })
                } else {
                    checkIcon.classList.add('disabled');
                    checkIcon.classList.add('error');
                }
            } else {
                classList.remove('error');
                checkIcon.classList.remove('error');
                checkIcon.classList.remove('disabled');
                this.setState({input: event.target.value})
            }
        }
        if(this.state.props.textChange) {
            this.state.props.textChange(event)
        }
    }

    dropdownError(txt, attribute) {
        return !attribute.possibleValues.filter(value => !value.hidden).some(e => e.key === txt) || attribute.errorMessage != null;
    }
}

function valueIsString(attribute) {
    return typeof attribute?.readableValue === 'string'
}

